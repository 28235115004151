import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IContactsPage } from '../../../../types/strapi/contacts';
import { formatText } from '../../../../utils';
import GridWrapper from '../../../grid-wrapper';
import LinkCard from '../../../LinkCard';
import { Picture } from '../../../Picture';
import Text from '../../../text';

import FeedbackLinks from './components/FeedbackLinks';

import './index.css';

interface IProps {
    data: IContactsPage
}

const Contacts = ({ data }: IProps) => {
    const cssBlock = 'contacts-section';
    const cn = useClassnames();
    return (
        <GridWrapper className={cn(cssBlock)}>
            <div className={cn(`${cssBlock}__social`)}>
                <FeedbackLinks data={data} />
            </div>
            <Text size={2} className={cn(`${cssBlock}__title`)} dangerouslySetInnerHTML={{ __html: formatText(data.aboutText) }} />
            {data.cities.map(({ image, title, description, href }) => (
                <LinkCard
                    key={title}
                    className={cn(`${cssBlock}__card`)}
                    title={title}
                    description={description}
                    href={href}
                    theme="dark"
                    titleClassName={`${cssBlock}__card-title`}
                    target="_blank"
                >
                    {(image?.desktopNormal || image?.desktopSmall || image?.tablet || image?.mobile) && (
                        <Picture
                            className={cn(`${cssBlock}__card-picture`)}
                            image={image}
                            alt={image.alt || ''}
                        />
                    )}
                </LinkCard>
            ))}
        </GridWrapper>
    );
};

export default Contacts;

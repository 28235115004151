import { GetServerDataReturn } from 'gatsby';
import React from 'react';

import ContactsPage from '../components/ContactsPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IContactsPage } from '../types/strapi/contacts';
import { IGlobalServerRuntimeData } from '../types/strapi/global';

type TProp = TPageProp<IServerRuntimeData>;

const Contacts: React.FC<TProp> = ({ serverData }) => {
    return (
        <ContactsPage data={serverData} />
    );
};

export default Contacts;

const query = `
  query ContactsPage ($locale: String) {
    ${GLOBAL_QUERY}
    contactsPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      headerText
      socialNetworkLabel
      socialNetwork {
        text
        to
      }
      prEmailLabel
      prEmail
      commercialProposalsEmailLabel
      commercialProposalsEmail
      aboutText
      cities {
        title
        description
        href
        image {
          desktopNormal {
            url
          }
          desktopSmall {
            url
          }
          tablet {
            url
          }
          mobile {
            url
          }
          alt
        }
      }
      formHeader
    }
  }
`;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    contactsPage: IContactsPage
}

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: 'ru'
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        return {
            props: {
                navPanel    : data.data.navPanel,
                global      : data.data.global,
                respondForm : data.data.respondForm,
                footer      : data.data.footer,
                contactsPage: data.data.contactsPage
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}

import React from 'react';

import { useClassnames } from '../../../../../../../../hooks/use-classnames';
import Heading from '../../../../../../../heading';
import Text from '../../../../../../../text';
import { IContactLink } from '../../types';

import './index.css';

interface IContactItem {
    title: string,
    links: Array<IContactLink>,
    className?: string
}

const rootClassName = 'contact-item';

const ContactItem = ({ title, links, className }: IContactItem) => {
    const cn = useClassnames();

    return (
        <React.Fragment>
            <Text size={4}>{title}</Text>
            <div className={cn(`${rootClassName}__list`, className)}>
                {links.map((link) => (
                    <Heading
                        key={link.name}
                        level={4} as="span"
                        className={cn(`${rootClassName}__item`)}
                    >
                        <a
                            href={link.href}
                            target={link.target}
                            rel="noopener noreferrer"
                            className={cn(`${rootClassName}__link`)}
                        >
                            {link.name}
                        </a>
                    </Heading>
                ))}
            </div>
        </React.Fragment>
    );
};

export default ContactItem;

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import Heading from '../heading';
import title from '../ModalForm/components/Title';
import { IPictureVariants } from '../Picture';
import Text from '../text';

import ArrowLeft from './assets/arrow-left.inline.svg';

import style from './index.css';


export interface ILinkCardData {
    title: string,
    description: string,
    image?: IPictureVariants & { alt?: string },
    href? : string,
    children?: React.ReactNode,
    target?: '_blank' | '_self'
}

interface IProps extends ILinkCardData {
    className?: string,
    theme?: 'dark' | 'light',
    titleClassName?: string,
    onMouseEnter?: React.MouseEventHandler
}

const LinkCard = ({
    className,
    title,
    description,
    href,
    children,
    theme = 'light',
    target = '_self',
    titleClassName,
    onMouseEnter

}: IProps) => {
    const cn = useClassnames(style);
    const cssBlock = 'link-card';

    const [isBlockNavigate, setIsBlockNavigate] = useState<boolean>(false);

    useEffect(() => {
        if(title === 'ФЛИП') {
            setIsBlockNavigate(true);
        }
    }, []);

    return (
        <div className={cn(className, `${cssBlock}__wrapper`, `${cssBlock}__wrapper_theme_${theme}`)} onMouseEnter={onMouseEnter}>
            {href && !isBlockNavigate && (
                <React.Fragment>
                    <Link
                        target={target}
                        to={href}
                        className={cn(`${cssBlock}__link`)}
                        aria-label={`Ссылка на страницу «${title}»`}
                    />

                    <ArrowLeft className={cn(`${cssBlock}__icon-arrow-left`)} />
                </React.Fragment>

            )}
            <div className={cn(`${cssBlock}__wrapper-inner`)}>
                <div className={cn(`${cssBlock}__сontent`)}>
                    {children}
                </div>
                <Heading
                    className={cn(`${cssBlock}__title`, titleClassName)}
                    level={4}
                    dangerouslySetInnerHTML={{ __html: title }}
                />

                <Text
                    className={cn(`${cssBlock}__description`)}
                    size={4}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
        </div>
    );
};

export default LinkCard;

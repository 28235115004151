import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IFooter } from '../../../../types/strapi/global';
import FooterDisclamer from '../../../FooterDisclamer';

import './index.css';

import { TLocale } from '../../../../utils/i18n';

const rootClassName = 'contact-footer';

interface IProps {
    data: IFooter,
    lang?: TLocale
}

const ContactFooter = ({ data, lang }: IProps) => {
    const cn = useClassnames();
    return (
        <footer className={cn(rootClassName)}>
            <FooterDisclamer lang={lang} disclamerClassName={`${rootClassName}__disclaimer`} data={data} />
        </footer>
    );
};

export default ContactFooter;
